import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Form, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEye, faFile, faSearch} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import {get} from '../../../controllers/endpoints.controller';
import {getUserToken, logout} from '../../../controllers/user.controller';

export default function CourtFiles(){

    const PAGE_TITLE = "Archivos";
    const PAGE_ICON = faFile;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;
        getCourtFiles();
    }, []);

    const setupTable = function(){
        const cols = ['Nombre', 'Cliente', 'Inicio', 'Acciones'];
        setTableColumns(cols);
    };

    const getCourtFiles = async function(){
        const req = await get('/proceedings/archived', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            let result = [];

            for(let r of res){
                //r.school = <Badge bg='' className='content-table status' style={{backgroundColor: `${r.debt.school.color}`}}>{r.debt.school.code}</Badge>;
                //r.status.name = <Badge bg='' className='content-table status' style={{backgroundColor: `${r.status.color}`}}>{r.status.name}</Badge>;
                r.action = <Link to={`/expedientes/detalles/?id=${r.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
                //r.inactividad = parseInt((moment.duration(moment().diff(r.updatedAt))).asDays()) + (parseInt((moment.duration(moment().diff(r.updatedAt))).asDays()) == 1 ? ' día' : ' días');
                result.push(r);
            }
            setTableRows(result);
            setupTable();
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textFee" style={{width: 250, display: 'inline'}} placeholder=""  />
                                </td>
                                <td className='content-options rows right'>
                                    
                                </td>
                            </tr>
                        </table>

                        {tableRows.length > 0 ?
                            <table striped hover className='mb-3'>
                                <thead>
                                    <tr>
                                        {tableColumns.map((c, i) => (
                                            <th key={i} className='content-table head'>{c}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows.map((r, i) => (
                                        <tr key={i} >
                                            <td className='content-table rows middle align-middle'>{r.name}</td>
                                            <td className='content-table rows middle align-middle'>{r.customer}</td>
                                            <td className='content-table rows middle align-middle' style={{width: 100}}>{<Moment format="DD/MM/YYYY">{r.createdAt}</Moment>}</td>
                                            <td className='content-table rows middle align-middle' style={{width: 150}}>{r.action}</td>
                                        </tr>
                                    ))}
                                    
                                </tbody>
                            </table>
                        :
                            <Container className='mt-5 mb-5'>
                                <Row>
                                    <Col style={{textAlign: 'center', fontSize: 60}}>
                                        <FontAwesomeIcon icon={faFile} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{textAlign: 'center'}}>
                                        No hay expedientes archivados
                                    </Col>
                                </Row>
                            </Container>

                        }

                        
                    </Card>
                </Row>
            </Container>
        </div>
    )
}