import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight, faCoins, faEye, faPlusCircle, faSchool, faSearch} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import {get} from '../../../controllers/endpoints.controller';
import {getUserToken} from '../../../controllers/user.controller';
import {removeTildes} from '../../../tools/chains';


export default function NewDebtStep2(){

    const PAGE_TITLE = "Nueva mora";
    const PAGE_ICON = faCoins;
    const [families, setFamilies] = React.useState([]);
    const [schools, setSchools] = React.useState([]);
    const [familiesSearched, setFamiliesSearched] = React.useState([]);
    const [schoolsSearched, setSchoolsSearched] = React.useState([]);
    const [showSearchFamiliesModal, setShowSearchFamiliesModal] = React.useState(false);
    const [showSearchSchoolsModal, setShowSearchSchoolsModal] = React.useState(false);
    const [students, setStudents] = React.useState([{name: ''}]);
    const [family, setFamily] = React.useState({});
    const [school, setSchool] = React.useState({});

    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;
        getFamilies();
        getSchools();
    }, []);

    const getFamilies = async function(){
        const req = await get('/persons/families', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setFamilies(res);
        }else{
            alert(res.message);
        }
    }

    const getSchools = async function(){
        const req = await get('/schools', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setSchools(res);
        }else{
            alert(res.message);
        }
    }

    const getFamilyPersons = async function(familyId){
        const req = await get(`/persons/byFamilyId/${familyId}`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            document.getElementById('textFather').value = `${res.father.surname}, ${res.father.name}`;
            document.getElementById('textMother').value = `${res.mother.surname}, ${res.mother.name}`;
            setStudents(res.students);
        }else{
            alert(res.message);
        }
    }

    const handleCloseSearchFamiliesModal = () => {
        setShowSearchFamiliesModal(false);
    };

    const handleOpenSearchFamiliesModal = () => {
        setShowSearchFamiliesModal(true);
    };

    const handleCloseSearchSchoolsModal = () => {
        setShowSearchSchoolsModal(false);
    };

    const handleOpenSearchSchoolsModal = () => {
        setShowSearchSchoolsModal(true);
    };

    const handleFamilySelected = (index) => {
        for(let f of families){
            if(f.id == index){
                setFamily(f);
                document.getElementById('textFamily').value = f.name;
                getFamilyPersons(f.id);
                setShowSearchFamiliesModal(false);
            }
        }
    };

    const handleSchoolSelected = (index) => {
        for(let s of schools){
            if(s.id == index){
                setSchool(s);
                document.getElementById('textSchool').value = s.name;
                setShowSearchSchoolsModal(false);
            }
        }
    };

    const handleSearchFamilies = (event) => {
        let result = [];
        let aux1;
        let aux2;
        const chain = event.target.value;
        for(let f of families){
            aux1 = removeTildes(f.name).toLowerCase();
            aux2 = removeTildes(chain).toLowerCase();
            if(aux1.includes(aux2)){
                result.push(f);
            }
        }
        
        setFamiliesSearched(result);
    };

    const handleSearchSchools = (event) => {
        let result = [];
        let aux1;
        let aux2;
        const chain = event.target.value;
        for(let s of schools){
            aux1 = removeTildes(s.name).toLowerCase();
            aux2 = removeTildes(chain).toLowerCase();
            if(aux1.includes(aux2)){
                result.push(s);
            }
        }
        
        setSchoolsSearched(result);
    };


    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={'/moras'} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Gestión de deudas
                                    </Link>
                                    <span className='ms-3' style={{color: '#A0A0A0'}}>|</span><span className='ms-3'>Datos generales de la mora</span>
                                    
                                </td>
                                <td className='content-options rows right'>
                                    
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>
                            <Form>
                                <table className='w-100'>
                                    <tr>
                                        <td style={{width: 120, fontWeight: 'bold'}}>Colegio</td>
                                        <td className=''>
                                            <Form.Group>
                                                <Form.Control type="text" id="textSchool" disabled placeholder="" />
                                            </Form.Group>
                                        </td>
                                        <td className='ps-2' style={{width: 30}}>
                                            <Button variant="primary" href={"#"} onClick={handleOpenSearchSchoolsModal}><FontAwesomeIcon icon={faSearch}/></Button>

                                            <Modal show={showSearchSchoolsModal} onHide={handleCloseSearchSchoolsModal}>
                                                <Modal.Header closeButton>
                                                <Modal.Title>Buscar colegio</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Control type="text" id="" placeholder="Nombre del colegio" onChange = {(event) => handleSearchSchools(event)} />
                                                    <ListGroup className='mt-4'>
                                                        {schoolsSearched.map((fs, i) => (
                                                            <ListGroup.Item key={i} onClick={() => handleSchoolSelected(fs.id)}>{fs.name}</ListGroup.Item>
                                                        ))}
                                                    </ListGroup>
                                            
                                                </Modal.Body>
                                                <Modal.Footer>
                                                <Button variant="secondary" onClick={handleCloseSearchSchoolsModal}>
                                                    Cerrar
                                                </Button>
                                                <Button variant="primary" onClick={handleCloseSearchSchoolsModal}>
                                                    Crear nuevo colegio
                                                </Button>
                                                <Button variant="success" onClick={handleCloseSearchSchoolsModal}>
                                                    Aceptar
                                                </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-4' style={{width: 120, fontWeight: 'bold'}}>Familia</td>
                                        <td className='pt-4'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textFamily" disabled placeholder="" />
                                            </Form.Group>
                                        </td>
                                        <td className='ps-2 pt-4' style={{width: 30}}>
                                            <Button variant="primary" href={"#"} onClick={handleOpenSearchFamiliesModal}><FontAwesomeIcon icon={faSearch}/></Button>

                                            <Modal show={showSearchFamiliesModal} onHide={handleCloseSearchFamiliesModal}>
                                                <Modal.Header closeButton>
                                                <Modal.Title>Buscar familia</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Control type="text" id="" placeholder="Apellido de la familia" onChange = {(event) => handleSearchFamilies(event)} />
                                                    <ListGroup className='mt-4'>
                                                        {familiesSearched.map((fs, i) => (
                                                            <ListGroup.Item key={i} onClick={() => handleFamilySelected(fs.id)}>{fs.name}</ListGroup.Item>
                                                        ))}
                                                    </ListGroup>
                                            
                                                </Modal.Body>
                                                <Modal.Footer>
                                                <Button variant="secondary" onClick={handleCloseSearchFamiliesModal}>
                                                    Cerrar
                                                </Button>
                                                <Button variant="primary" onClick={handleCloseSearchFamiliesModal}>
                                                    Crear nueva familia
                                                </Button>
                                                <Button variant="success" onClick={handleCloseSearchFamiliesModal}>
                                                    Aceptar
                                                </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-4' style={{fontWeight: 'bold'}}>Padre</td>
                                        <td className='pt-4'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textFather" disabled placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td className='pt-2' style={{fontWeight: 'bold'}}>Madre</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textMother" disabled placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Alumnos</td>
                                        <td className='pt-1'>
                                            <Form.Group>
                                                {students.map((s, i) => (
                                                    <Form.Control type="text" key={i} id="textStudents" disabled placeholder="" className='mt-1' value={`${s.name != '' ? s.surname : ''}${s.name != '' ? ', ' : ''}${s.name != '' ? s.name : ''}`} />
                                                ))}
                                            </Form.Group>
                                        </td>
                                    </tr>
                                </table>
                            </Form>

                            <div className='mt-5' style={{textAlign: 'right'}}>
                                <Link to={`/moras/nueva/2?familyid=${family.id}&schoolid=${school.id}`}><Button variant="success">Siguiente<FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 8}} /></Button></Link>
                            </div>
                            
                        </Container>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}