import React, {useEffect} from 'react'
// eslint-disable-next-line
import { Routes, Route, Link } from "react-router-dom";
import {Container, Dropdown, Nav, Badge, Navbar} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBell, faCog, faSignOutAlt, faUser} from '@fortawesome/free-solid-svg-icons'
import {faCircle} from '@fortawesome/free-regular-svg-icons'
import {colors} from '../../../tools/constants';
import { get, put } from '../../../controllers/endpoints.controller';
import {getUserRole, getUserToken, logout} from '../../../controllers/user.controller';
import { timeSince } from '../../../tools/tools';
import {params} from '../../../tools/constants'

export default function NavBar(){
    const [notifications, setNotifications] = React.useState([]);
    const [menus, setMenus] = React.useState([]);

    useEffect(() => {
        processMenus()
        getMyNotifications()
       
    }, []);

    const getMyNotifications = async function(){
        const req = await get(`/notifications`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setNotifications(res);
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const setNotificationAsSeen = async function(notificationId, url){
        const req = await put(`/notifications/seen`, getUserToken(), {notificationId: notificationId});
        const res = await req.json();

        if(req.status === 200){
            window.location.href = url;
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const processMenus = () => {
        let m = [];
        const role = getUserRole();

        if(role == "Administrador"){
            m = [{text: "Inicio", link: "/inicio"},
            {text: "Calendario", link: "/calendario"},
            {text: "Tareas", link: "/tareas"},
            {text: "Clientes", link: "/clientes/personas"},
            {text: "Contrarios", link: "/demandados/personas"},
            {text: "Judicial", link: "/expedientes"},
            {text: "Archivo", link: "/archivo"},
            {text: "Sistema", link: "/sistema"}];
        }else if(role == "Empleado"){
            m = [{text: "Inicio", link: "/inicio"},
            {text: "Calendario", link: "/calendario"},
            {text: "Tareas", link: "/tareas"},
            {text: "Clientes", link: "/clientes/personas"},
            {text: "Contrarios", link: "/demandados/personas"},
            {text: "Judicial", link: "/expedientes"},
            {text: "Archivo", link: "/archivo"}];
        }

        setMenus(m)
    }

    /*const menus = [{text: "Inicio", link: "/inicio"},
                    {text: "Calendario", link: "/calendario"},
                    {text: "Tareas", link: "/tareas"},
                    {text: "Clientes", link: "/clientes/personas"},
                    {text: "Contrarios", link: "/demandados/personas"},
                    {text: "Judicial", link: "/expedientes"},
                    {text: "Archivo", link: "/archivo"},
                    {text: "Sistema", link: "/sistema"}];*/

    /*
const menus = [{text: "Inicio", link: "/"},
                    {text: "Calendario", link: "/calendario"},
                    {text: "Tareas", link: "/tareas"},
                    {text: "Clientes", link: "/clientes/personas"},
                    {text: "Contrarios", link: "/demandados/personas"},
                    {text: "Gestión de deudas", link: "/moras"},
                    {text: "Judicial", link: "/expedientes"},
                    {text: "Archivo", link: "/archivo"},
                    {text: "Administración", link: "/administracion"}];

    */

    const checkPage = function(key){
        const actualUri = window.location.pathname.split('/')[1];
        const actualMenu = menus[key].link.split('/')[1];

        if(actualUri === actualMenu){
            return(
                <Link to={menus[key].link} className="navbar-menu-active pe-3 ps-3 text-light">{menus[key].text}</Link>
            )
        }else{
            return(
                <Link to={menus[key].link} className="navbar-menu-not-active pe-3 ps-3 text-light">{menus[key].text}</Link>
            )
        }
    };
    
    return(
        <div style={{marginBottom: 125}}>
            <Navbar fixed="top" className="pb-2" style={{background: "white"}}>
                <Container>
                    <Navbar.Brand>
                        <img
                            src="https://eac.com.ar/wp-content/uploads/2022/01/Logos-1_6AC-sf.png"
                            height="60"
                            className="d-inline-block align-top"
                            alt="Estudio Andreini Cocchis"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text className='me-3'>
                            <Dropdown align="end">
                                <Dropdown.Toggle variant="light" id="dropdown-basic" style={{zIndex: '9', height: 52}}>
                                    <FontAwesomeIcon icon={faBell} className='ms-2' style={{marginRight: 8}} />{notifications.length > 0 ? <Badge bg="danger" className='me-2'>{notifications.length}</Badge> : <span></span>}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {notifications.length == 0 ? <p className='m-0 p-3' style={{width: 220, textAlign: 'center'}}>No hay notificaciones</p> : ""}
                                    {notifications.map((n, i) => (
                                        <Dropdown.Item onClick={() => setNotificationAsSeen(n.id, n.url)}><p className='m-0'><FontAwesomeIcon icon={faCircle} style={{marginRight: 8}} />{n.title}: {n.body}</p><p className='m-0' style={{paddingLeft: 24, fontSize: 10}}>{timeSince(n.createdAt)}</p></Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Text>

                        <Navbar.Text>
                            
                        <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{background: `${colors().brand_blue}`, borderColor: `${colors().brand_blue}`, zIndex: '9'}}>
                                    <img className='avatar-10' src={`${params().dataUri}${localStorage.getItem('userAvatar')}`} alt=""></img>
                                    <span className="ms-3 me-3">{`${localStorage.getItem('userName')} ${localStorage.getItem('userSurname')}`}</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href='/configuracion/usuarios/detalles/'><FontAwesomeIcon icon={faUser} style={{marginRight: 10}} />Mi perfil</Dropdown.Item>
                                    <Dropdown.Item href='/configuracion'><FontAwesomeIcon icon={faCog} style={{marginRight: 8}} />Configuración</Dropdown.Item>
                                    <Dropdown.Item onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} style={{marginRight: 9}} />Salir</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>


                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Navbar fixed="top" style={{background: `${colors().brand_blue}`, marginTop: 85, zIndex: '10', height: 41}}>
                <Container>
                    <Nav className="flex-grow-1 justify-content-center pb-0">
                        {menus.map((menu, i) => (
                            <div key={i}>{checkPage(i)}</div>
                        ))}
                    </Nav>
                </Container>
            </Navbar>
        </div>
    )
}