const DEV = false;

const DEV_PARAMS = {
    apiUri: "http://localhost:15000/api",
    homePage: "http://localhost:3000/inicio",
    dataUri: 'https://lex.eac.com.ar/data'
};

const PROD_PARAMS = {
    apiUri: "https://ar-caba-sv4.seconline.net.ar:16621/api",
    homePage: "https://lex.eac.com.ar/inicio",
    dataUri: 'https://lex.eac.com.ar/data'
};

const COLORS = {
    brand_blue: '#49A5DF',
    brand_clear_blue: '#0D72B5',
    green: '#28A745'
}

export const params = function () {
    if(DEV){
        return DEV_PARAMS;
    }else{
        return PROD_PARAMS;
    }
}

export const colors = function () {
    return COLORS;
}