import React, {useEffect} from 'react'
import {Form, Button, Container, Row, Col} from "react-bootstrap";
import {post} from '../../../controllers/endpoints.controller';
import {saveUserData} from '../../../controllers/user.controller';
import {validateUser} from '../../../controllers/user.controller';
import NavBar from "../navbar/Navbar";

export default function Index(){

    useEffect(() => {
        validateUser();
    }, []);

    return (
        <div>
            
        </div>
    );
}