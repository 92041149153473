import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col, Spinner} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight, faCamera, faCircle, faCoins, faDotCircle, faEye, faPen, faPlusCircle, faSchool, faSearch, faUser} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import SubNavBarC from "../navbar/SubNavbarCustomers";
import SubNavBarD from "../navbar/SubNavbarDefendants";
import {get, post, put} from '../../../controllers/endpoints.controller';
import {getUserToken, logout, saveAvatar} from '../../../controllers/user.controller';
import { params } from '../../../tools/constants';
import { delay, fileToBase64 } from '../../../tools/tools';


export default function UserDetails(props){

    const PAGE_TITLE = "Mis datos";
    const PAGE_ICON = faUser;

    const [roles, setRoles] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState(0);
    const [selectedIsLawer, setSelectedIsLawer] = React.useState(false);
    const [name, setName] = React.useState('');
    const [showEditAvatarModal, setShowEditAvatarModal] = React.useState(false);
    const [showEditPasswordModal, setShowEditPasswordModal] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState({});
    const [isEditing, setIsEditing] = React.useState(false)
    const [imgAvatar, setImgAvatar] = React.useState('')
    const [newAvatar, setNewAvatar] = React.useState({});
    const [confirmButton, setConfirmButton] = React.useState('Confirmar');
    const [newPassword1, setNewPassword1] = React.useState('')
    const [newPassword2, setNewPassword2] = React.useState('')
    const [passwordVerification, setPasswordVerification] = React.useState('')

    const handleNewPassword1 = (event) => {
        setNewPassword1(event.target.value);
        checkPassword()
    };

    const handleNewPassword2 = (event) => {
        setNewPassword2(event.target.value);
        checkPassword()
    };

    const handleNewAvatar = async (event) => {
        const file = event.target.files[0]
        const base64 = await fileToBase64(file)
        setNewAvatar({
            extension: `.${file.name.split('.')[file.name.split('.').length - 1]}`,
            file: base64
        });
    }     

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;
        getUser()
    }, []);

    const handleCloseEditAvatarModal = () => {
        setShowEditAvatarModal(false);
        setNewAvatar({})
    };

    const handleOpenEditAvatarModal = () => {
        setShowEditAvatarModal(true);
    };

    const handleCloseEditPasswordModal = () => {
        setShowEditPasswordModal(false);
    };

    const handleOpenEditPasswordModal = () => {
        setShowEditPasswordModal(true);
    };

    const getUser = async function(){

        const req = await get(`/users/byToken`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            console.log(res)
            processUser(res)
            setName(`${res.name} ${res.surname}`)
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
        
        
    }

    const processUser = (data) => {
        document.getElementById('textName').value = data.name;
        document.getElementById('textSurname').value = data.surname;
        document.getElementById('textDni').value = data.dni;
        document.getElementById('textEmail').value = data.email;
        setImgAvatar(`${params().dataUri}${data.avatar}`)

        /*if(data.Person){
            const c = data.Person;
            document.getElementById('textCustomer').value = `DNI ${c.dni} - ${c.surname.toUpperCase()}, ${c.name}`;
        }
        if(data.Business){
            const c = data.Business;
            document.getElementById('textCustomer').value = `CUIT ${c.cuit} - ${c.name}`;
        }*/
    }

    const editPassword = async function(){
        if(checkPassword()){
            const req = await put(`/users/password`, getUserToken(), {password: newPassword1});
            const res = await req.json();
    
            if(req.status === 201){
                
                handleCloseEditPasswordModal();
            }else if(req.status === 403){
                logout();
            }else{
                alert(res.message);
            }
        }else{
            alert('La contraseña no cumple con los requisitos de seguridad')
        }
        
    }

    const editAvatar = async function(){
        setConfirmButton(<span><Spinner animation="grow" variant="light" size="sm" /><Spinner animation="grow" variant="light" size="sm" className='ms-1'/><Spinner animation="grow" variant="light" size="sm" className='ms-1' /></span>)
        const req = await put(`/users/avatar`, getUserToken(), newAvatar);
        const res = await req.json();

        if(req.status === 201){
            await delay(2000);
            saveAvatar(res.path)
            setImgAvatar(res.path)
            window.location.reload();
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
        setConfirmButton('Confirmar');
    }

    const checkPassword = () => {
        const p1 = document.getElementById('textPassword1').value;
        const p2 = document.getElementById('textPassword2').value;
        const ss = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        const uc = /([A-Z])+/
        const nu = /([0-9])+/
        let specialSymbol = false;
        let upperCase = false;
        let number = false;
        let textLenght = false;
        let confirm = false;

        if(ss.test(p1)){
            specialSymbol = true;
        }

        if(uc.test(p1)){
            upperCase = true;
        }

        if(nu.test(p1)){
            number = true;
        }

        if(p1.length > 7){
            textLenght = true;
        }

        if(p1 === p2){
            confirm = true;
        }

        setPasswordVerification(
            <div className='mt-3'>
                <p className='m-0'>{upperCase ? <FontAwesomeIcon icon={faCircle} style={{color: 'green'}}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCircle} style={{color: 'red'}}></FontAwesomeIcon>}<span className='ps-2'>1 letra mayúscula</span></p>
                <p className='m-0'>{number ? <FontAwesomeIcon icon={faCircle} style={{color: 'green'}}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCircle} style={{color: 'red'}}></FontAwesomeIcon>}<span className='ps-2'>1 número</span></p>
                <p className='m-0'>{specialSymbol ? <FontAwesomeIcon icon={faCircle} style={{color: 'green'}}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCircle} style={{color: 'red'}}></FontAwesomeIcon>}<span className='ps-2'>1 carácter especial</span></p>
                <p className='m-0'>{textLenght ? <FontAwesomeIcon icon={faCircle} style={{color: 'green'}}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCircle} style={{color: 'red'}}></FontAwesomeIcon>}<span className='ps-2'>Más de 7 caracteres</span></p>
                <p className='m-0'>{confirm ? <FontAwesomeIcon icon={faCircle} style={{color: 'green'}}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCircle} style={{color: 'red'}}></FontAwesomeIcon>}<span className='ps-2'>Contraseñas coinciden</span></p>
            </div>
        )

        return specialSymbol && upperCase && number && confirm && textLenght;
    }
  

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                    <p className='m-0' style={{color: '#A0A0A0', fontSize: 25, fontWeight: 300}}>{PAGE_TITLE}</p>
                                    <p className='m-0' style={{color: '#A0A0A0', fontSize: 45, fontWeight: 300}}>{name}</p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                <span style={{fontSize: 18}}>Mis datos</span>
                                    
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="warning" onClick={handleOpenEditPasswordModal}><FontAwesomeIcon icon={faPen} style={{marginRight: 8}} />Cambiar contraseña</Button>
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>
                        <Row>
                            <Col xs={6}>
                                <table className='w-100 mb-3'>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold', width: 180}}>Nombre</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" readOnly={!isEditing} id="textName" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Apellido</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" readOnly={!isEditing} id="textSurname" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>DNI</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" readOnly={!isEditing} id="textDni" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Correo electrónico</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" readOnly={!isEditing} id="textEmail" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>         
                                                            
                                </table>
                            </Col>
                            <Col xs={6} style={{textAlign: 'center'}}>
                                <p><img src={imgAvatar} className='avatar-60' style={{marginTop:15}}></img></p>
                                <Button variant="primary" onClick={handleOpenEditAvatarModal}><FontAwesomeIcon icon={faCamera} className='me-2'></FontAwesomeIcon>Cambiar imagen</Button>
                            </Col>
                            </Row>
                            <Form>

                                
                            </Form>
                           
                            
                        </Container>
                    </Card>
                </Row>
            </Container>

            <Modal show={showEditAvatarModal} onHide={handleCloseEditAvatarModal}>
                <Modal.Header>
                <Modal.Title>Cambiar foto de perfil</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{textAlign: 'center'}}>
                    {newAvatar.file != null ? 
                        <p><img src={newAvatar.file} className='avatar-60'></img></p>
                    :
                        <p className='m-0'></p>
                    }
                    <p className='mb-0'><Form.Control accept='.png, .jpg, .jpeg' onChange = {(event) => handleNewAvatar(event)} type="file" /></p>
            
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEditAvatarModal}>
                    Cerrar
                </Button>
                <Button variant="success" onClick={editAvatar}>
                    {confirmButton}
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditPasswordModal} onHide={handleCloseEditPasswordModal}>
                <Modal.Header>
                <Modal.Title>Cambiar contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mt-0'>
                        <Form.Text>Nueva contraseña</Form.Text>
                        <Form.Control type="password" id="textPassword1" placeholder="" onChange={(event) => handleNewPassword1(event)} />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Confirmar contraseña</Form.Text>
                        <Form.Control type="password" id="textPassword2" placeholder="" onChange={(event) => handleNewPassword2(event)} />
                    </Form.Group>
                    {passwordVerification}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEditPasswordModal}>
                    Cerrar
                </Button>
                <Button variant="success" onClick={editPassword}>
                    {confirmButton}
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}