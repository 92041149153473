import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Col, Dropdown, Badge, Modal, Form, Spinner} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faCoins, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faDownload, faTrash} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import {get, post, put} from '../../../controllers/endpoints.controller';
import {getUserToken} from '../../../controllers/user.controller';
import { faEdit, faFileExcel, faFileWord } from '@fortawesome/free-regular-svg-icons';
import { pn } from '../../../tools/chains';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { processDate, timeDue } from '../../../tools/tools';
import moment from 'moment';

export default function DebtDetails(){

    const PAGE_TITLE = "GDD";
    const PAGE_ICON = faCoins;
    const [tasks, setTasks] = React.useState([]);
    const [debt, setDebt] = React.useState({});
    const [family, setFamily] = React.useState({});
    const [familyFather, setFamilyFather] = React.useState({});
    const [familyMother, setFamilyMother] = React.useState({});
    const [familyStudents, setFamilyStudents] = React.useState([]);
    const [familyDebtors, setFamilyDebtors] = React.useState([]);
    const [dueColumns, setDueColumns] = React.useState([]);
    const [dues, setDues] = React.useState([]);
    const [debtDetailsColumns, setDebtDetailsColumns] = React.useState([]);
    const [debtDetails, setDebtDetails] = React.useState([]);
    const [proceedingActionsColumns, setProceedingActionsColumns] = React.useState([]);
    const [proceedingActions, setProceedingActions] = React.useState([]);
    const [customerContacts, setCustomerContacts] = React.useState([]);
    const [selectedCustomerContacts, setSelectedCustomerContacts] = React.useState({});
    const [lawers, setLawers] = React.useState([]);
    const [selectedLawer, setSelectedLawer] = React.useState({});
    const [selectedDebtor, setSelectedDebtor] = React.useState({});
    const [preAgreementButton, setPreAgreementButton] = React.useState(<span></span>);
    const [showPreAgreementModal, setShowPreAgreementModal] = React.useState(false);
    const handleClosePreAgreementModal = () => {setShowPreAgreementModal(false);};
    const handleShowPreAgreementModal = () => {
        setPreAgreementButton(<span><FontAwesomeIcon icon={faDownload} className='me-1'></FontAwesomeIcon>Descargar</span>)
        getCustomerContacts();
        getLawers();
        setShowPreAgreementModal(true);
    };
    const [preAgreementDate, setPreAgreementDate] = React.useState(new Date());
    const [showNewActionModal, setShowNewActionModal] = React.useState(false);
    const [actionCategories, setActionCategories] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [selectedActionCategory, setSelectedActionCategory] = React.useState({});
    const handleCloseNewActionModal = () => {setShowNewActionModal(false);};
    const handleShowNewActionModal = () => {
        getActionCategories();
        getLawers();
        setShowNewActionModal(true);
    };
    const [showNewTaskModal, setShowNewTaskModal] = React.useState(false);
    const [reminderOptions, setReminderOptions] = React.useState([]);
    const [newTaskDate, setNewTaskDate] = React.useState(new Date());
    const [newTaskReminder, setNewTaskReminder] = React.useState({});
    const [newTaskUser, setNewTaskUser] = React.useState({});
    const handleCloseNewTaskModal = () => {setShowNewTaskModal(false);};
    const handleShowNewTaskModal = () => {
        getReminderOptions();
        getLawers();
        setShowNewTaskModal(true);
    };

    const handlePreAgreementDate = (event) => {
        setPreAgreementDate(event);
    };

    const handlePreAgreementApoderadoLegalNombre = (event) => {
        let dni = "";
        for(let cc of customerContacts){
            if(event.target.value == cc.id){
                setSelectedCustomerContacts(cc);
                for(let cd of cc.ContactData){
                    if(cd.param == "DNIN"){
                        dni = cd.data;
                    }
                }
            }
        }
        document.getElementById('preagreementApoderadoLegalDNI').value = dni;
    };

    const handlePreAgreementLawer = (event) => {
        setSelectedLawer(event.target.value)
    };

    const handlePreAgreementDebtor = (event) => {
        setSelectedDebtor(event.target.value)
    };

    const handleNewActionUser = (event) => {
        setSelectedLawer(event.target.value)
    };

    const handleNewActionCategory = (event) => {
        setSelectedActionCategory(event.target.value)
    };

    const handleNewTaskUser = (event) => {
        setNewTaskUser(event.target.value)
    };

    const handleNewTaskReminder = (event) => {
        setNewTaskReminder(event.target.value)
    };

    const handleNewTaskDate = (event) => {
        setNewTaskDate(event);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });
        registerLocale('es', es);
        document.title = `EAC - ${PAGE_TITLE}`;
        
        getDebtById(getProceedingId());
        getTasks();
    }, []);

    const getProceedingId = function (){
        const params = new URLSearchParams(window.location.search);
        if(params.has('id')){
            return params.get('id');
        }else{
            return -1;
        }
    }

    const getDebtById = async function(id){
        const req = await get(`/proceedings/debts/byId/${id}`, getUserToken());
        const res = await req.json();
        
        if(req.status === 200){
            setDebt(res.debt);
            setFamily(res.debt.family);
            parseFamily(res.debt.family);
            document.title = `EAC - ${PAGE_TITLE} Flia. ${res.debt.family.name}`;
            setDueColumns(['#', 'Monto', 'Intereses', 'Honorarios', 'Total', 'Estado', 'Acciones']);
            let dues = [];
            for(let d of res.debt.dues){
                d.statusName = <Badge bg='' className='content-table status' style={{backgroundColor: `${d.debt.status.color}`}}>{d.debt.status.name}</Badge>;
                d.action = <Button variant="success"><FontAwesomeIcon icon={faCheck} /></Button>;
                dues.push(d);
            }
            setDues(dues);   
            setDebtDetailsColumns(['Alumno', 'Fecha', 'Importe', 'Dias', 'Intereses', 'Total', 'Referencia']);
            let debtDetails = [];
            for(let dd of res.debt.debtDetails){
                //d.action = <Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button>;
                dd.studentName = `${dd.person.surname}, ${dd.person.name}`
                debtDetails.push(dd);
            }
            setDebtDetails(debtDetails);     
            
            setProceedingActionsColumns(['Fecha', 'Categoría', 'Tarea', 'Encargado']);
            let pa = [];
            for(let dad of res.Actions){
                //d.action = <Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button>;
                dad.category = <Badge bg='' className='content-table status' style={{backgroundColor: `${dad.actionCategory.color}`}}>{dad.actionCategory.name}</Badge>;
                dad.createdBy = `${dad.User.name} ${(dad.User.surname).substring(0,1)}.`;
                pa.push(dad);
            }
            setProceedingActions(pa);
            
        }else{
            alert(res.message);
        }
    }

    const parseFamily = function(family){
        try{
            let debtors = [];
            let students = [];
            for(let f of family.persons){
                if(f.personType.name == 'Padre'){
                    setFamilyFather(f);
                    debtors.push(f);
                }else if(f.personType.name == 'Madre'){
                    setFamilyMother(f);
                    debtors.push(f);
                }else{
                    students.push(f);
                }
            }
            setFamilyDebtors(debtors);
            setFamilyStudents(students);
        }catch(err){

        }
    }

    const getCustomerContacts = async function(){
        const schoolId = debt.school.id;

        const req = await get(`/schools/contacts/${schoolId}`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setCustomerContacts(res);
        }else{
            alert(res.message);
        }
    }

    const getLawers = async function(){
        const req = await get(`/users/lawers`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setLawers(res)
        }else{
            alert(res.message);
        }
    }

    const getReminderOptions = async function(){
        const req = await get(`/tasks/reminderOptions/`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setReminderOptions(res)
        }else{
            alert(res.message);
        }
    }

    const getTasks = async function(){
        const req = await get(`/tasks/byProceedingId/${getProceedingId()}`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setTasks(res)
        }else{
            alert(res.message);
        }
    }

    const getActionCategories = async function(){
        const req = await get(`/proceedings/actions/categories`, getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setActionCategories(res)
        }else{
            alert(res.message);
        }
    }

    const setTaskAsCompleted = async function(taskId){
        const req = await put(`/tasks/byProceedingId/asCompleted/`, getUserToken(), {taskId: taskId, proceedingId: getProceedingId()});
        const res = await req.json();

        if(req.status === 200){
            getTasks();
        }else{
            alert(res.message);
        }
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const createPreAgreement = async function(){
        setPreAgreementButton(<span><Spinner animation="grow" variant="light" size="sm" /><Spinner animation="grow" variant="light" size="sm" className='ms-1'/><Spinner animation="grow" variant="light" size="sm" className='ms-1' /></span>)

        const pa = {
            contactId: selectedCustomerContacts.id,
            lawerId: selectedLawer,
            debtorId: selectedDebtor,
            proceedingId: getProceedingId(),
            date: preAgreementDate
        };

        const req = await post(`/proceedings/debts/preagreement/`, getUserToken(), pa);
        const res = await req.json();

        if(req.status === 201){
            await delay(500);
            triggerBase64Download(`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${res.data}`, 'archivo');
            await delay(500);
            handleClosePreAgreementModal();
        }else{
            alert(res.message);
            setPreAgreementButton(<span><FontAwesomeIcon icon={faDownload} className='me-1'></FontAwesomeIcon>Descargar</span>)
        }
    }

    const createAction = async function(){
        const ac = {
            proceedingId: getProceedingId(),
            userId: selectedLawer,
            categoryId: selectedActionCategory,
            fileId: null,
            description: document.getElementById('newActionDescription').value
        };

        const req = await post(`/proceedings/actions/`, getUserToken(), ac);
        const res = await req.json();

        if(req.status === 201){
            getDebtById(getProceedingId());
            handleCloseNewActionModal();
        }else{
            alert(res.message);
        }
    }

    const createTask = async function(){
        const task = {
            proceedingId: getProceedingId(),
            userId: newTaskUser,
            dueDate: newTaskDate,
            alertId: newTaskReminder,
            title: document.getElementById("newTaskTitle").value,
            description: document.getElementById("newTaskDescription").value,
            isEvent: true
        };

        const req = await post(`/tasks/`, getUserToken(), task);
        const res = await req.json();

        if(req.status === 201){
            getTasks();
            handleCloseNewTaskModal();
        }else{
            alert(res.message);
        }
    }

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td>
                                    <p className='m-0' style={{color: '#A0A0A0', fontSize: 25, fontWeight: 300}}>EXP-GDD #{debt.id}</p>
                                    <p className='m-0' style={{color: '#A0A0A0', fontSize: 45, fontWeight: 300}}>Flia. {family.name}</p>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={'/moras'} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Gestión de deudas
                                    </Link>
                                    <span className='ms-3' style={{color: '#A0A0A0'}}/>
                                </td>
                                <td className='content-options rows right'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <span style={{marginRight: 10}}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleShowPreAgreementModal}><FontAwesomeIcon icon={faFileWord} style={{marginRight: 11, marginLeft: 3}} />Generar pre-acuerdo</Dropdown.Item>
                                            <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon={faEdit} style={{marginRight: 8, marginLeft: 2}} />Cambiar estado</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </table>

                        <Container>
                            <Row className='mt-4 mb-0'>
                                <Col>
                                    <p className='column-title'>Datos de la deuda</p>
                                </Col>
                                <Col>
                                    <p className='column-title'>Datos del Padre</p> 
                                </Col>
                                <Col>
                                    <p className='column-title'>Datos de la Madre</p>
                                </Col>
                            </Row>
                            <Row className='mb-4' style={{marginTop: -16}}>
                                <Col>
                                    <div className='column-content-nh mb-2'>
                                        <table>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Capital</td>
                                                <td className='ps-5'>${pn(debt.capital)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Intereses</td>
                                                <td className='ps-5'>${pn(debt.interests)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Honorarios</td>
                                                <td className='ps-5'>${pn(debt.totalFee)} ({debt.fee}%)</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Total deuda</td>
                                                <td className='ps-5'><Badge bg="secondary" style={{fontSize: 14}}>${pn(debt.totalDebt)}</Badge></td>
                                            </tr>
                                            
                                        </table>
                                    </div>

                                    <p className='column-title mb-0'>Datos del alumno/s</p>
                                    <div className='column-content-nh'>
                                        <table>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Alumnos</td>
                                                <td className='ps-5'>{familyStudents.map((r, i) => (
                                                    <p key={i} className='m-0'>{r.name} {r.surname}</p>
                                                ))}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='column-content'>
                                        <table>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Nombre</td>
                                                <td className='ps-5'>{familyFather.surname}, {familyFather.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>DNI</td>
                                                <td className='ps-5'>{familyFather.dni}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Teléfono</td>
                                                <td className='ps-5'>{familyFather.phone}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Celular</td>
                                                <td className='ps-5'>{familyFather.mobile}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Correo</td>
                                                <td className='ps-5'>{familyFather.email}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Trabajo</td>
                                                <td className='ps-5'>{familyFather.isWorking ? `Si - ${familyFather.placeWork}` : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Domicilio</td>
                                                <td className='ps-5'>{familyFather.address}<br></br>{familyFather.city != null ? `${familyFather.city}`: ''}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </Col>
                                <Col>
                                <div className='column-content'>
                                        <table>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Nombre</td>
                                                <td className='ps-5'>{familyMother.surname}, {familyMother.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>DNI</td>
                                                <td className='ps-5'>{familyMother.dni}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Teléfono</td>
                                                <td className='ps-5'>{familyMother.phone}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Celular</td>
                                                <td className='ps-5'>{familyMother.mobile}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Correo</td>
                                                <td className='ps-5'>{familyMother.email}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Trabajo</td>
                                                <td className='ps-5'>{familyMother.isWorking ? `Si - ${familyMother.placeWork}` : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontWeight: 'bold'}}>Domicilio</td>
                                                <td className='ps-5'>{familyMother.address}<br></br>{familyMother.city != null ? `${familyFather.city}`: ''}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='mt-4 mb-0'>
                                <Col>
                                    <p className='column-title'>Tareas</p>
                                </Col>
                            </Row>
                            <Row className='mb-4' style={{marginTop: -16}}>
                                <Col>
                                    <div className='column-content'>
                                        <div className='mt-0 mb-3' style={{textAlign: 'right'}}>
                                            <Button variant="success" onClick={handleShowNewTaskModal}><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nueva tarea</Button>
                                        </div>
                                        

                                        {tasks.map((t, i) => (
                                            <div className='file element mb-2' >
                                                <table>
                                                    <tr>
                                                        <td className='w-100'>
                                                            <p className='m-0'>{t.title}</p>
                                                            <p className='m-0' style={{fontSize: 12}}>{t.description}</p>
                                                            <p className='mt-1 mb-0'><Badge bg='success' className=''>Vence en {timeDue(t.dueDate)}</Badge></p>
                                                            <p className='mt-2 mb-0' style={{fontSize: 12}}><img className='me-1' style={{width: 20, borderRadius: "50%"}} src="https://secdevs.com.ar/cbimage.png" alt=""></img> <span style={{marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle'}}>{t.User.name} {t.User.surname}</span></p>
                                                        </td>
                                                        <td className='w-100'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                                    <span style={{marginRight: 10}}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => setTaskAsCompleted(t.id)}><FontAwesomeIcon icon={faCheck} style={{marginRight: 10, marginLeft: 2}} />Marcar como completado</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </Row>

                            <Row className='mt-4 mb-0'>
                                <Col>
                                    <p className='column-title'>Gestión</p>
                                </Col>
                            </Row>
                            <Row className='mb-4' style={{marginTop: -16}}>
                                <Col>
                                    <div className='column-content'>
                                        <div className='mt-0 mb-3' style={{textAlign: 'right'}}>
                                            <Button variant="success" onClick={handleShowNewActionModal}><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nueva actividad</Button>
                                        </div>
                                        <table striped hover className='mb-3 w-100'>
                                            <thead>
                                                <tr>
                                                    {proceedingActions.length > 0 ? proceedingActionsColumns.map((c, i) => (
                                                        <th key={i} className='content-table head'>{c}</th>
                                                    )) : <Container className='mt-4 mb-4'>
                                                        <Row>
                                                            <Col style={{textAlign: 'center', fontSize: 40}}>
                                                                <FontAwesomeIcon icon={faCommentAlt} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col style={{textAlign: 'center'}}>
                                                                No se han realizado actividades
                                                            </Col>
                                                        </Row>
                                                    </Container>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {proceedingActions.map((r, i) => (
                                                    <tr key={i} >
                                                        <td className='content-table rows middle align-middle' style={{width: 150}}>{<Moment format="DD/MM/YYYY">{r.createdAt}</Moment>}</td>
                                                        <td className='content-table rows middle align-middle' style={{width: 150}}>{r.category}</td>
                                                        <td className='content-table rows align-middle'>{r.description}</td>
                                                        <td className='content-table rows middle align-middle' style={{width: 200}}>{r.createdBy}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                </Col>
                            </Row>

                            <Row className='mt-4 mb-0'>
                                <Col>
                                    <p className='column-title'>Liquidación</p>
                                </Col>
                            </Row>
                            <Row className='mb-4' style={{marginTop: -16}}>
                                <Col>
                                    <div className='column-content'>
                                    <table striped hover className='mb-3 w-100'>
                                        <thead>
                                            <tr>
                                                {debtDetailsColumns.map((c, i) => (
                                                    <th key={i} className='content-table head'>{c}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {debtDetails.map((r, i) => (
                                                <tr key={i} >
                                                    <td className='content-table rows middle align-middle ps-4'>{r.studentName}</td>
                                                    <td className='content-table rows middle align-middle'>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</td>
                                                    <td className='content-table rows middle align-middle'>${pn(r.amount)}</td>
                                                    <td className='content-table rows middle align-middle'>{r.days}</td>
                                                    <td className='content-table rows middle align-middle'>${pn(r.interests)}</td>
                                                    <td className='content-table rows middle align-middle'>${pn(r.total)}</td>
                                                    <td className='content-table rows align-middle'>{r.description}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    </div>
                                </Col>
                            </Row>


                            

                            
                        </Container>
                    </Card>
                </Row>

                
            </Container>

            <Modal show={showPreAgreementModal} onHide={handleClosePreAgreementModal}>
                <Modal.Header closeButton>
                <Modal.Title>Generar pre-acuerdo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Text>Nombre Apoderado legal</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange = {(event) => handlePreAgreementApoderadoLegalNombre(event)}>
                            <option value="0">Seleccionar...</option>
                            {customerContacts.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>DNI Apoderado legal</Form.Text>
                        <Form.Control type="text" id="preagreementApoderadoLegalDNI" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Deudor</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange = {(event) => handlePreAgreementDebtor(event)}>
                            <option value="0">Seleccionar...</option>
                            {familyDebtors.map((p, i) => (
                                <option key={i} id={i} value={p.dni}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Abogado</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange = {(event) => handlePreAgreementLawer(event)}>
                            <option value="0">Seleccionar...</option>
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha liquidación</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='textDebtDate' dateFormat="dd/MM/yyyy" selected={preAgreementDate} onChange={(d) => handlePreAgreementDate(d)} />
                    </Form.Group>
                    
                    
            
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClosePreAgreementModal}>
                    Cerrar
                </Button>

                <Button variant="success" onClick={createPreAgreement}>{preAgreementButton}</Button>
                

                </Modal.Footer>
            </Modal>

            <Modal show={showNewActionModal} onHide={handleCloseNewActionModal}>
                <Modal.Header closeButton>
                <Modal.Title>Nueva actividad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <Form.Group>
                        <Form.Text>Encargado</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange = {(event) => handleNewActionUser(event)}>
                            <option value="0">Seleccionar...</option>
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Categoría</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange = {(event) => handleNewActionCategory(event)}>
                            <option value="0">Seleccionar...</option>
                            {actionCategories.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Tarea</Form.Text>
                        <Form.Control as="textarea" rows={4} id="newActionDescription" placeholder="" />
                    </Form.Group>
            
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseNewActionModal}>
                    Cerrar
                </Button>

                <Button variant="success" onClick={createAction}>Crear actividad</Button>
                

                </Modal.Footer>
            </Modal>


            <Modal show={showNewTaskModal} onHide={handleCloseNewTaskModal}>
                <Modal.Header closeButton>
                <Modal.Title>Nueva tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <Form.Group>
                        <Form.Text>Encargado</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange = {(event) => handleNewTaskUser(event)}>
                            <option value="0">Seleccionar...</option>
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="newTaskTitle" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="newTaskDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' showTimeSelect locale="es" id='' dateFormat="dd/MM/yyyy HH:mm" selected={newTaskDate} onChange={(d) => handleNewTaskDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Recordar</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange = {(event) => handleNewTaskReminder(event)}>
                            <option value="0">No recordar</option>
                            {reminderOptions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
            
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseNewTaskModal}>
                    Cerrar
                </Button>

                <Button variant="success" onClick={createTask}>Crear tarea</Button>
                

                </Modal.Footer>
            </Modal>
        </div>
    )
}