import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Dropdown, Form, Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCoins, faEye, faPlusCircle, faSearch} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import {get, post} from '../../../controllers/endpoints.controller';
import {getUserToken} from '../../../controllers/user.controller';
import { timeSince } from '../../../tools/tools';


export default function Debts(){

    const PAGE_TITLE = "Gestión de deudas";
    const PAGE_ICON = faCoins;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;
        getDebts();
    }, []);

    const getDebts = async function(){
        const req = await get('/proceedings/debts', getUserToken());
        const res = await req.json();

        
        if(req.status === 200){
            processDebts(res)
        }else{
            alert(res.message);
            console.log(res)
        }
    }

    const processDebts = function(data){
        let result = [];
        if(data.length > 0){

        
        for(let r of data){
            try{
                    //r.Business.name = <Badge bg='' className='content-table status' style={{backgroundColor: `${r.Business.color}`}}>{r.Business.name}</Badge>;
                    r.action = <Link to={`/moras/detalles/?id=${r.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
                    r.inactividad = timeSince(r.updatedAt);
                    result.push(r);
                }catch(e){
                    console.log(e)
                }
            }
        } 
        setTableRows(result);
        setupTable();
    }

    const setupTable = function(){
        const cols = ['Familia', 'Cliente', 'Inicio', 'Inactividad', 'Encargado', 'Acciones'];
        setTableColumns(cols);
    };

    const handleSearch = async function(event){
        const chain = {
            chain: event.target.value
        };
        const req = await post('/proceedings/search/Deuda', getUserToken(), chain);
        const res = await req.json();

        if(req.status === 200){
            processDebts(res)
        }else{
            alert(res.message);
            console.log(res)
        }
    }

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textFee" style={{width: 250, display: 'inline'}} placeholder="" onChange = {(event) => handleSearch(event)} />
                                </td>
                                <td className='content-options rows right'>
                                    <Link to={'/moras/nueva/1'}><Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nuevo expendiente</Button></Link>
                                </td>
                            </tr>
                        </table>

                        <table striped hover className=' mb-3'>
                            <thead>
                                <tr>
                                    {tableColumns.map((c, i) => (
                                        <th key={i} className='content-table head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.map((r, i) => (
                                    <tr key={i} >
                                        <td className='content-table rows align-middle ps-4'>{r.debt.family.name}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 400}}>{r.Business.name}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 100}}>{<Moment format="DD/MM/YYYY">{r.createdAt}</Moment>}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 120}}>{r.inactividad}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 150}}>{}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 90}}>{r.action}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}