import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Col, Form} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight, faCheck, faCoins} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import {get, post} from '../../../controllers/endpoints.controller';
import {getUserToken} from '../../../controllers/user.controller';

export default function NewDebtStep2(){

    const PAGE_TITLE = "Nueva mora";
    const PAGE_ICON = faCoins;
    const [familyId, setFamilyId] = React.useState(0);
    const [schoolId, setSchoolId] = React.useState(0);
    const [students, setStudents] = React.useState([]);
    const [debtTem, setDebtTem] = React.useState(0.0);
    const [debtFee, setDebtFee] = React.useState(0.0);
    const [tableDebtDetailsColumns, setTableDebtDetailsColumns] = React.useState([]);
    const [debtDetails, setDebtDetails] = React.useState([]);
    const [debtDate, setDebtDate] = React.useState('');
    const [debtStudent, setDebtStudent] = React.useState({});
    const [debtData, setDebtData] = React.useState({});
    
    useEffect(() => {
        init();
    }, []);

    const init = function(){
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });
        registerLocale('es', es);
        document.title = `EAC - ${PAGE_TITLE}`;
        
        setTableDebtDetailsColumns(['Alumno', 'Fecha', 'Importe', 'Dias', 'Intereses', 'Total', 'Referencia', '']);
        getParams();
        getRate();

        document.getElementById('textCapital').value = "0.00";
        document.getElementById('textInterests').value = "0.00";
        document.getElementById('textFeeTotal').value = "0.00";
        document.getElementById('textTotalDebt').value = "0.00";
    }

    const getParams = function(){
        const params = new URLSearchParams(window.location.search);
        setFamilyId(params.get('familyid')); 
        getFamilyPersons(params.get('familyid'));
        getSchoolData(params.get('schoolid'));
        setSchoolId(params.get('schoolid'));
    }

    const getRate = async function(){
        document.getElementById('textRate').value = "Obteniendo de BNA...";
        const req = await get('/proceedings/debts/tem', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setDebtTem(res);
            document.getElementById('textRate').value = res;
        }else{
            alert(res.message);
        }
    }

    const getFamilyPersons = async function(fid){
        if(!isNaN(fid)){
            if(fid > 0){

                const req = await get(`/persons/byFamilyId/${fid}`, getUserToken());
                const res = await req.json();
        
                if(req.status === 200){
                    setStudents(res.students);
                    document.getElementById('textFather').value = `${res.father.surname.toUpperCase()}, ${res.father.name}`;
                    document.getElementById('textMother').value = `${res.mother.surname.toUpperCase()}, ${res.mother.name}`;
                }else{
                    alert(res.message);
                }
            }
        }
    }

    const getSchoolData = async function(id){
        if(!isNaN(id)){
            if(id > 0){
                const req = await get(`/customers/byId/${id}`, getUserToken());
                const res = await req.json();
        
                if(req.status === 200){
                    document.getElementById('textClient').value = res.name;
                }else{
                    alert(res.message);
                }
            }
        }
    }

    const addDetail = () => {
        let dd = debtDetails;
        dd.push({
            studentId: debtStudent.dni,
            studentName: debtStudent.completeName,
            amount: parseFloat(document.getElementById('textDetailAmount').value).toFixed(2),
            days: parseInt(document.getElementById('textDetailDays').value),
            interests: parseFloat(document.getElementById('textDetailInterests').value),
            total: parseFloat(document.getElementById('textDetailTotal').value),
            description: document.getElementById('textDetailDescription').value,
            date: new Date(debtDate) //2019-06-01T00:00:00
        })
        setDebtDetails([...dd]);
        resetFields();
        refreshData(dd);
    }

    const refreshData = (newData) => {
        let data = [];
        if(newData === null){
            data = debtDetails;
        }else{
            data = newData;
        }

        let capital = 0;
        let interests = 0;
        let fee = 0;
        let total = 0;

        for(let d of data){
            capital += parseFloat(d.amount);
            interests += parseFloat(d.interests);
        }

        fee = parseFloat(document.getElementById('textFee').value) * (capital + interests) / 100;
        total = capital + interests + fee;
        setDebtData({
            capital: capital,
            interests: interests,
            fee: fee,
            total: total
        });

        document.getElementById('textCapital').value = capital.toFixed(2);
        document.getElementById('textInterests').value = interests.toFixed(2);
        document.getElementById('textFeeTotal').value = isNaN(fee.toFixed(2)) ? "0.00" : fee.toFixed(2);
        document.getElementById('textTotalDebt').value = isNaN(total.toFixed(2)) ? "0.00" : total.toFixed(2);
    }

    const resetFields = () => {
        document.getElementById('textDebtStudent').value = 'Seleccionar alumno...';
        setDebtDate('');
        document.getElementById('textDetailAmount').value = '';
        document.getElementById('textDetailDays').value = '';
        document.getElementById('textDetailInterests').value = '';
        document.getElementById('textDetailTotal').value = '';
        document.getElementById('textDetailDescription').value = '';
    }

    const handleDebtStudent = (event) => {
       let name1 = event.target.value;
       let name2;
       for(let s of students){
            name2 = `${s.surname}, ${s.name}`;
            if(name1 === name2){
                s.completeName = name1;
                setDebtStudent(s);
            }
       }
    };

    const handleDetailAmount = (event) => {
        calcDebtDetail('amount', parseFloat(event.target.value.replace('/,/g', '.')));
    };

    const handleDetailDate = (event) => {
        setDebtDate(event);
        calcDebtDetail('date', event);
    };

    const handleFee = (event) => {
        const f = event.target.value;
        if(f > 100){
            document.getElementById('textFee').classList.add('bg-warning');
        }else{
            document.getElementById('textFee').classList.remove('bg-warning');
            setDebtFee(event.target.value);
            refreshData(null);
        }
    };

    const calcDebtDetail = function(dataFrom, value){
        if(dataFrom === 'date'){
            let a = document.getElementById('textDetailAmount').value.replace('/,/g', '.');
            if(!isNaN(a) && a !== ''){
                const days = parseInt((moment.duration(moment().diff(moment(value)))).asDays());
                const interests = ((parseFloat(debtTem) / 30) * days) * a / 100;   
                const total = a + interests;
                document.getElementById('textDetailDays').value = days;
                document.getElementById('textDetailInterests').value = interests;
                document.getElementById('textDetailTotal').value = total;
            }else{
                const days = parseInt((moment.duration(moment().diff(moment(value)))).asDays());
                document.getElementById('textDetailDays').value = days;
            }
        }else if(dataFrom === 'amount'){
            if(debtDate !== ''){
                const days = parseInt((moment.duration(moment().diff(moment(debtDate)))).asDays());
                const interests = ((parseFloat(debtTem) / 30) * days) * parseFloat(value) / 100;   
                const total = parseFloat(value) + interests;
                document.getElementById('textDetailDays').value = days;
                document.getElementById('textDetailInterests').value = interests.toFixed(2);
                document.getElementById('textDetailTotal').value = total.toFixed(2);
            }
        }
    }

    const createDebt = async () => {
        const data = {
            schoolId: schoolId,
            familyId: familyId,
            fee: parseFloat(document.getElementById('textFee').value),
            rate: parseFloat(document.getElementById('textRate').value),
            details: debtDetails,
            notifications: null
        };

        const req = await post('/proceedings/debts/new', getUserToken(), data);
        const res = await req.json();

        if(req.status === 201){
            window.location.href = `/moras/nueva/3?debtid=${res.debtId}`;
        }else{
            alert(res.message);
        }
    }




    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={'/moras'} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Gestión de deudas
                                    </Link>
                                    <span className='ms-3' style={{color: '#A0A0A0'}}>|</span><span className='ms-3'>Datos financieros de la mora</span>
                                    
                                </td>
                                <td className='content-options rows right'>
                                    
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-1 mb-4'>
                            <Row>
                                <Col>
                                    <Row className='mt-4 mb-0'>
                                        <Col>
                                            <p className='column-title'>Datos de cliente y familia</p>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4' style={{marginTop: -16}}>
                                        <Col>
                                            <div className='column-content'>
                                                <Form>
                                                    <table className='w-100'>
                                                        <tr>
                                                            <td style={{width: 170, fontWeight: 'bold'}}>Cliente</td>
                                                            <td className=''>
                                                                <Form.Group>
                                                                    <Form.Control className='' disabled type="text" id="textClient" placeholder="" />
                                                                </Form.Group>
                                                            </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td className='pt-2' style={{fontWeight: 'bold'}}>Padre</td>
                                                            <td className='pt-2'>
                                                                <Form.Group>
                                                                    <Form.Control className='' disabled type="text" id="textFather" placeholder="" />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pt-2' style={{fontWeight: 'bold'}}>Madre</td>
                                                            <td className='pt-2'>
                                                                <Form.Group>
                                                                    <Form.Control className='' disabled type="text" id="textMother" placeholder="" />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pt-2' style={{fontWeight: 'bold'}}>Alumnos</td>
                                                            <td className='pt-2'>
                                                                <Form.Group>
                                                                {students.map((s, i) => (
                                                                    <Form.Control type="text" key={i} id="textStudents" disabled placeholder="" className='mt-1' value={`${s.name !== '' ? s.surname.toUpperCase() : ''}${s.name !== '' ? ', ' : ''}${s.name !== '' ? s.name : ''}`} />
                                                                ))}
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </Form>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='mt-4 mb-0'>
                                        <Col>
                                            <p className='column-title'>Datos de deuda</p>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4' style={{marginTop: -16}}>
                                        <Col>
                                            <div className='column-content'>
                                                <Form>
                                                    <table className='w-100'>
                                                        <tr>
                                                            <td style={{width: 170, fontWeight: 'bold'}}>Tasa de interés (%)</td>
                                                            <td className=''>
                                                                <Form.Group>
                                                                    <Form.Control className='w-50' type="text" id="textRate" placeholder="" />
                                                                </Form.Group>
                                                            </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td className='pt-2' style={{fontWeight: 'bold'}}>Honorarios (%)</td>
                                                            <td className='pt-2'>
                                                                <Form.Group>
                                                                    <Form.Control className='w-50' type="text" id="textFee" placeholder="" onChange = {(event) => handleFee(event)} />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pt-2' style={{fontWeight: 'bold'}}>Capital</td>
                                                            <td className='pt-2'>
                                                                <Form.Group>
                                                                    <Form.Control className='w-50' disabled type="text" id="textCapital" placeholder="" />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pt-2' style={{fontWeight: 'bold'}}>Intereses</td>
                                                            <td className='pt-2'>
                                                                <Form.Group>
                                                                    <Form.Control className='w-50' disabled type="text" id="textInterests" placeholder="" />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pt-2' style={{fontWeight: 'bold'}}>Honorarios</td>
                                                            <td className='pt-2'>
                                                                <Form.Group>
                                                                    <Form.Control className='w-50' disabled type="text" id="textFeeTotal" placeholder="" />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pt-2' style={{fontWeight: 'bold'}}>Total deuda</td>
                                                            <td className='pt-2'>
                                                                <Form.Group>
                                                                    <Form.Control className='w-50' disabled type="text" id="textTotalDebt" placeholder="" />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </Form>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>













                            
                            <Row className='mt-4 mb-0'>
                                <Col>
                                    <p className='column-title'>Liquidación</p>
                                </Col>
                            </Row>
                            <Row className='mb-4' style={{marginTop: -16}}>
                                <Col>
                                    <div className='column-content'>
                                    <Form className=''>
                                        <table className='w-100'>
                                            <thead>
                                                <tr>
                                                    {tableDebtDetailsColumns.map((c, i) => (
                                                        <th key={i} className='content-table head'>{c}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {debtDetails.map((r, i) => (
                                                    <tr key={i} >
                                                        <td className='content-table rows middle align-middle ps-4'>{r.studentName}</td>
                                                        <td className='content-table rows middle align-middle'>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</td>
                                                        <td className='content-table rows middle align-middle'>${r.amount}</td>
                                                        <td className='content-table rows middle align-middle'>{r.days}</td>
                                                        <td className='content-table rows middle align-middle'>${r.interests}</td>
                                                        <td className='content-table rows middle align-middle'>${r.total}</td>
                                                        <td className='content-table rows align-middle'>{r.description}</td>
                                                        <td className='content-table rows align-middle'></td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td className='content-table rows middle align-middle' style={{width: 220}}>
                                                        <Form.Select id='textDebtStudent' onChange = {(event) => handleDebtStudent(event)}>
                                                            <option value="0">Seleccionar alumno...</option>
                                                            {students.map((s,i) => (
                                                                <option key={i} id={"student" + s.id} value={s.id}>{`${s.surname}, ${s.name}`}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </td>
                                                    <td className='content-table rows middle align-middle'>
                                                        <DatePicker className='form-control cover content rows element' locale="es" id='textDebtDate' dateFormat="dd/MM/yyyy" selected={debtDate} onChange={(d) => handleDetailDate(d)} />
                                                    </td>
                                                    <td className='content-table rows middle align-middle'>
                                                        <Form.Control type="text" id="textDetailAmount" placeholder="" onChange = {(event) => handleDetailAmount(event)}/>
                                                    </td>
                                                    <td className='content-table rows middle align-middle'>
                                                        <Form.Control type="text" id="textDetailDays" disabled placeholder="" />
                                                    </td>
                                                    <td className='content-table rows middle align-middle'>
                                                        <Form.Control type="text" id="textDetailInterests" disabled placeholder="" />
                                                    </td>
                                                    <td className='content-table rows middle align-middle'>
                                                        <Form.Control type="text" id="textDetailTotal" disabled placeholder="" />
                                                    </td>
                                                    <td className='content-table rows middle align-middle' style={{width: 300}}>
                                                        <Form.Control type="text" id="textDetailDescription" placeholder="" />
                                                    </td>
                                                    <td className='content-table rows middle align-middle' style={{width: 60}}>
                                                        <Button variant="success" onClick={addDetail}><FontAwesomeIcon icon={faCheck} /></Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            
                                        </table>
                                    </Form>
                                    </div>
                                </Col>
                            </Row>


                            

                            <div className='mt-5' style={{textAlign: 'right'}}>
                                <Button variant="success" onClick={createDebt}>Confirmar<FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 8}} /></Button>
                            </div>
                        </Container>
                        
                    </Card>
                </Row>
            </Container>
        </div>
    )
}